
.wrapper {
  margin-top: 100px;
}

a{
  cursor: pointer;
}
button,
.btn,
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0 !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.modal-content {
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
}

.product-line:hover {
  background-color: rgb(11, 94, 215);
  color: white !important;
}

.product-line:hover td {
  color: white !important;
}

.quotation-preview tbody {
  display: block;
  overflow: auto;
}
.quotation-preview thead,.quotation-preview tfoot, .quotation-preview tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;/* even columns width , fix width of table too*/
}
.quotation-preview thead,.quotation-preview tfoot {
  width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
}
.mn23{
  background-color: rgb(221, 217, 196) !important; 

  font-size: 14px;
  font-weight: bold;
}
table td{
  font-size: 12;
  height: 10 !important;
}
.quotation{
  font-size: 12px;
}
.table>:not(caption)>*>* {
  padding: .1rem .1rem !important;
  background-color: var(--bs-table-bg);
  font-size: 12px;
  border-bottom-width: 0.5px !important;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.mouse-pointer:hover{
  cursor: pointer;
}

.mouse-pointer-blue:hover,.mouse-pointer-blue:hover *{
  background-color:#0d6efd;
  color: #fff !important;
}